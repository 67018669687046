import * as React from 'react';

export default function HomeHero() {
    return (
        <section className="hero-welcome-wrapper hero-1 home">
            <div className="single-slide text-white">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-12 col-lg-10 offset-lg-1 offset-xl-0">
                            <div className="hero-contents">
                                <h1 style={{marginTop: '20px'}}>Building a Better Future.</h1>
                                <h2>
                                    Meaningful Careers in Construction & Healthcare.
                                </h2>
                            </div>
                        </div>
                        <div className="col-xl-5 col-12 text-xl-end col-lg-10 offset-lg-1 offset-xl-0">
                            <div className="hero-mobile mt-5">
                                <img src="/images/background-home.png" alt="employers background"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
